
import { Grid } from "@mui/material";
import VerificationInput from "react-verification-input";
import useStyle from './styles.js'

export default function TesteComponente() {
  const style = useStyle();
  return (
    <>
      <h1>
        Pagina de Teste
      </h1>
      <Grid container spacing={2} >
        <Grid item md={12} className="w-full" sx={{ display: 'flex', justifyContent: 'center' }}>
          <VerificationInput
            classNames={{
              container: style.VontainerVerification,
              character: style.VerificationCaracter,
              characterInactive: style.VerificationCaracterInac,
              characterSelected: style.VerificationCaracterSelec,
              characterFilled: style.VerificationCaracterFil,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
