import { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Hidden,
  Skeleton,
  Typography,
} from "@mui/material";
import CellWifiIcon from "@mui/icons-material/CellWifi";
import SimCardIcon from "@mui/icons-material/SimCard";
import {
  BreadCrumbsPaginas,
  ButtonAcaoGrande,
  Modal,
  PaginasMenuDashboard,
  Subtitulo,
  Titulo,
} from "../..";
import {
  addItemVitrine,
  getVitrine,
  getDispositivosCompotiveis,
} from "../../../api/Carrinho/ChipInternacional";
import { useAuth } from "../../../hooks/AuthContext";
import useAlert from "../../../hooks/useAlert";
import { formatMoneyInStringForm } from "../../../util/helpers";
import {
  GETDISPOSITIVOSCOMPATIVEIS,
  GETVITRINECHIP,
} from "../../../util/typesReactQuery";
import MenuDashboard from "../../MenuDashboard";
import Seletor from "../../Seletor";
import { Style } from "./styles";
import AlertCustomFixed from "../../AlertCustomFixed";
import parseHtml from "html-react-parser";
import theme from "../../Paleta/theme";

const breadCrumbs = [
  {
    label: "Chip Internacional",
    icon: <CellWifiIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    label: "Compra de Chip Internacional",
    icon: <SimCardIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
];

function VitrineChip() {
  const [vitrine, setVitrine] = useState([]);
  const [vitrineFiltrada, setVitrineFiltrada] = useState([]);
  const [paises, setPaises] = useState([]);
  const [selectedPais, setSelectedPais] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [dispositivosCompativeis, setDispositivosCompativeis] = useState("");
  const [selectedChip, setSelectedChip] = useState(null);
  const classes = Style();
  const { user } = useAuth();
  const { showAlertError, showAlertSuccess } = useAlert([]);
  const navigate = useNavigate();
  const [isVisibleModalCompat, setIsVisibleModalCompat] = useState(false);

  const { mutateAsync: comprarChip } = useMutation({
    mutationFn: addItemVitrine,
    onSuccess: (data) => {
      if (data?.errorMessage) {
        showAlertError(`Ops, algo deu errado: ${data?.errorMessage} - R3C03`);
        return;
      }
      showAlertSuccess("Oba, seu chip foi salvo no carrinho.");
      navigate("/ChipInternacional/Pagamento");
    },
    onError: (error) => {
      showAlertError(`Ops, algo deu errado: ${error?.message ?? error} - RXZTQ`);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  useQuery({
    queryKey: [GETVITRINECHIP],
    queryFn: getVitrine,
    onSuccess: (data) => {
      if (data?.errorMessage) {
        showAlertError(`Ops, algo deu errado: ${data.errorMessage}`);
        return;
      }
      const uniquePaises = [...new Set(data.data.map((item) => item.pais))];
      setPaises(uniquePaises.map((pais, index) => ({ id: index + 1, descricao: pais })));
      setVitrine(data.data);
    },
    onError: (error) => {
      showAlertError(error?.message ?? error);
    },
    onSettled: () => {
      setIsLoading(false);
    },
    refetchOnWindowFocus: false,
  });

  useQuery({
    queryKey: [GETDISPOSITIVOSCOMPATIVEIS],
    queryFn: getDispositivosCompotiveis,
    onSuccess: (data) => {
      if (data?.errorMessage) {
        showAlertError(`Ops, algo deu errado: ${data.errorMessage}`);
        return;
      }
      setDispositivosCompativeis(data.data);
    },
    onError: (error) => {
      showAlertError(`${error?.message ?? error} - RLUI1`);
    },
    onSettled: () => {
      setIsLoading(false);
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (vitrine.length > 0 && paises.length > 0 && selectedPais) {
      const filteredVitrine = vitrine.filter((item) => item.pais === paises[selectedPais - 1]?.descricao);
      setVitrineFiltrada(filteredVitrine);
      setSelectedChip(filteredVitrine[0]);
    }
  }, [vitrine, paises, selectedPais]);

  const handleComprarChip = async (chip) => {
    setIsLoading(true);

    if (!chip) {
      showAlertError("Ops, primeiro você deve selecionar um chip!");
      setIsLoading(false);
      return;
    }

    const data = {
      ...chip,
      idCliente: user.id,
      quantidade: 1, // Quantidade fixa definida como 1
    };

    await comprarChip(data);
  };

  const handleOpenModalCompat = () => {
    setIsVisibleModalCompat(true);
  };

  const handleSelectPais = (value) => {
    setSelectedPais(value);
  };

  return (
    <MenuDashboard pagina={PaginasMenuDashboard.ChipInternacional}>
      <Hidden smUp>
        <Box sx={{ pl: 2 }}>
          <BreadCrumbsPaginas list={breadCrumbs} />
        </Box>
      </Hidden>

      <Hidden smDown>
        <Box sx={{ pt: 2 }}>
          <BreadCrumbsPaginas list={breadCrumbs} />
        </Box>
      </Hidden>

      <Grid container justifyContent="center">
        {isLoading ? (
          <>
            <Grid item xs={12} md={6} lg={6}>
              <Skeleton animation="wave" height={60} />
            </Grid>

            <Grid container xs={12} md={7} spacing={3} lg={7} className={classes.containerChip}>
              {Array.from({ length: 6 }, (_, index) => (
                <Grid item spacing={4} xs={4} key={index}>
                  <Skeleton sx={{ borderRadius: 4 }} animation="wave" height={250} />
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <Grid item xs={12} md={7} lg={7} className={classes.containerChip}>
            <Typography variant="h4" sx={{ fontWeight: "bold", color: "#444", mb: 2, mt: 3 }}>
              Escolha seu plano de Chip Internacional
            </Typography>

            <Titulo sx={{ fontSize: 18 }}>
              Antes de continuar, confira se o seu aparelho é{" "}
              <Typography
                sx={{
                  fontSize: "inherit",
                  textTransform: "lowercase",
                  cursor: "pointer",
                  color: `${theme.palette.primary.main}`,
                }}
                variant="text"
                onClick={handleOpenModalCompat}
              >
                compatível
              </Typography>
              <br />
              Escolha a duração da sua viagem e o pacote desejado.
            </Titulo>

            <Grid sx={{  pr: 2, pt: 4 }} className={classes.SeletorPais}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Destino: {paises[selectedPais - 1]?.descricao}
              </Typography>
              <Seletor
                className={classes.Seletor}
                list={paises}
                setSelect={handleSelectPais}
                value={selectedPais}
              />
            </Grid>

            {vitrineFiltrada.length > 0 ? (
              <Grid container spacing={3}>
                {vitrineFiltrada.map((item, index) => (
                  <Grid item xs={12} md={4} key={index}>


<Box className={classes.IconChip}>
                            <Typography color="white">
                              Adquira seu chip
                            </Typography>
                          </Box>
                    <Card
                      onClick={() => handleComprarChip(item)}
                      variant="outlined"
                      className={classes.card}
                      sx={{
                        minHeight: "350px",
                        border: selectedChip?.id === item.id ? `2px solid ${theme.palette.primary.main}` : "none",
                      }}
                    >
                      <CardContent>
                        <Typography variant="h6" sx={{ fontSize: "28px", mb: 2, mt:2 }}>
                          {item?.dias} DIAS
                        </Typography>
                        <Subtitulo className={classes.Dados}>
                          Quantidade de dados: {item?.tamanho}
                        </Subtitulo>
                        <Box className={classes.Valor}>
                          <Typography sx={{ fontSize: 15, mr: 1 }}>R$</Typography>
                          <Typography sx={{ letterSpacing: "0.1rem", fontSize: "1.8rem", opacity: 0.9 }}>
                            {formatMoneyInStringForm(item?.valorBRL)}
                          </Typography>
                        </Box>
                        <Box sx={{ mt: 3 }}>
                          <ButtonAcaoGrande Handle={() => handleComprarChip(item)}>
                            Contratar
                          </ButtonAcaoGrande>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box sx={{ mt: 1, textAlign: "center" }}>
                <Typography>Não foi possível encontrar nenhum chip disponível no momento!</Typography>
              </Box>
            )}
          </Grid>
        )}
      </Grid>

      <Modal
        show={isVisibleModalCompat}
        close={() => setIsVisibleModalCompat(false)}
        hasButtonClose
      >
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" sx={{ mb: 1 }}>Dispositivos Compatíveis</Typography>
          {dispositivosCompativeis ? (
            parseHtml(dispositivosCompativeis)
          ) : (
            <AlertCustomFixed type="info">Não consta informação sobre os dispositivos compatíveis</AlertCustomFixed>
          )}
        </Box>
      </Modal>
    </MenuDashboard>
  );
}

export default VitrineChip;
