import { useState, useEffect, Fragment } from "react";
import CampoTexto from "../../Campos/CampoTexto";
import CampoCep from "../../Campos/CampoCep";
import CampoNumber from "../../Campos/CampoNumber";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import Seletor from "../../Seletor";
import Subtitulo from "../../Textos/Subtitulo";
import { Box, Button, Grid, Skeleton } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import useStyle from "./styles";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import CustomGrid from "../../GridCadastroCustom";
import IconButtonCustom from "../../Botoes/IconButtonCustom";
import AddIcon from "@mui/icons-material/Add";
import IconButtonDelete from "../../Botoes/IconButtonDelete";
import { PositionTooltip, TooltipInformacao } from "../../TooltipInformacao";
import CampoData from "../../Campos/CampoData";

export default function Controle({
  identificaItem,
  setData,
  defaultDadosForm,
  setDafaultDadosForm,
  setDadosForm,
  dadosForm,
  isLoading,
  idTipoEstadoCivil,
  setIdTipoEstadoCivil = 1,
  initBuscaCampos,
  title,
  tooltipAdd,
  tooltipDelete,
}) {
  const classes = useStyle();
  const enderecoDefault = {
    bairro: "",
    cep: "",
    cidade: "",
    complemento: "",
    logradouro: "",
    numero: "",
    idPais: 0,
    idUf: 0,
  };

  const [enderecoState, setEnderecoState] = useState(enderecoDefault);
  const [cepChanged, setCepChanged] = useState(false);

  const handleChangeEnderecoByCEP = (e) => {
    e = e.data;
    let newEndereco = enderecoState;
    newEndereco.cidade = e.cidade;
    newEndereco.bairro = e.bairro;
    newEndereco.logradouro = e.logradouro;
    newEndereco.ufDescricao = e.ufDescricao;
    newEndereco.idUf = e.idUf;
    newEndereco.idPais = e.idPais;
    newEndereco.numero = enderecoState.numero;
    setEnderecoState(newEndereco);

    // Clone do array original para evitar mutações diretas
    const newArray = dadosForm.map((objeto) => {
      const novoObjeto = { ...objeto };
      // Verifica se o objeto atual possui a propriedade 'campos'
      if (novoObjeto.campos) {
        // Cria um novo array de campos com os campos originais e apenas atualize o valor do campo desejado
        novoObjeto.campos = novoObjeto.campos.map((campo) => {
          if (campo.propriedade === "Cidade") {
            return { ...campo, valor: newEndereco.cidade };
          }
          if (campo.propriedade === "Bairro") {
            return { ...campo, valor: newEndereco.bairro };
          }
          if (campo.propriedade === "IDUf") {
            return { ...campo, valor: newEndereco.idUF };
          }
          if (campo.propriedade === "Logradouro") {
            return { ...campo, valor: newEndereco.logradouro };
          }

          // Mantenha os outros campos inalterados
          return campo;
        });
      }

      return novoObjeto;
    });

    // setData(dadosForm);
    setDadosForm(newArray);
  };

  const handleChange = (e) => {
    let newEndereco = { ...enderecoState, [e.target.name]: e.target.value };
    setEnderecoState((prevState) => ({ ...newEndereco }));
    if (e.target.name === "cep") setCepChanged(true);

    setData(newEndereco);
  };

  const formatDateValueInput = (value) => {
    let valor = "";
    if (value.includes("T")) {
      valor = value.split("T")[0];
    } else {
      valor = value;
    }

    return valor;
  };

  const deepCloning = (obj) => {
    if (!!obj) return JSON.parse(JSON.stringify(obj));

    return [];
  };

  const handlerChangeValue = (e, index) => {
    let dados = deepCloning(dadosForm);

    dados[index].campos = dados[index].campos.map((c) => {
      if (e.target.name === c.propriedade) c.valor = e.target.value;

      return c;
    });

    setDadosForm(() => [...dados]);
    setData(() => [...dados]);
  };

  const handleChangeCheck = (e, index) => {
    let newValue = deepCloning(dadosForm);

    newValue[index].campos = newValue[index].campos.map((c) => {
      if (c.propriedade === e.target.name)
        return { ...c, valor: e.target.checked };
      else return c;
    });

    setDadosForm(() => [...newValue]);
    setData(() => [...newValue]);
  };

  const handlerChangeSelect = (name, value, index) => {
    let dados = deepCloning(dadosForm);

    dados = dados.map((item, i) => {
      if (i === index) item[name] = value;

      return item;
    });

    setDadosForm(() => [...dados]);
    setData(() => [...dados]);

    if (name === "idTipoEstadoCivil") {
      setIdTipoEstadoCivil(value);
      initBuscaCampos(value, dados, index);
    }
  };

  const handlerChangeValuePercentual = (value, propriedade, index) => {
    let dados = deepCloning(dadosForm);

    dados[index].campos = dados[index].campos.map((obj) => {
      if (obj.propriedade === propriedade) {
        return {
          ...obj,
          valor: value,
        };
      }
      return obj;
    });

    setDadosForm(() => [...dados]);
    setData(() => [...dados]);
  };

  const limparDefaultCampos = (controle) => {
    let newData = controle;

    if (newData?.length > 0) {
      newData.campos = controle.campos.map((c) => {
        if (c.tipoControle.toUpperCase() === "DATA") c.valor = Date.now();
        else c.valor = "";

        c.enable = true;

        return c;
      });
    }

    return newData;
  };

  const handlerAddCampos = () => {
    let valorDefault = limparDefaultCampos(deepCloning(defaultDadosForm));

    setDadosForm((prevState) => [...prevState, valorDefault]);
    setData((prevState) => [...prevState, valorDefault]);
  };

  const handlerRemoveCampos = (index) => {
    setDadosForm((prevState) => {
      return prevState.filter((_, i) => i !== index);
    });
    setData((prevState) => {
      return prevState.filter((_, i) => i !== index);
    });
  };

  return (
    <>
      <div className={classes.box}>
        {isLoading ? (
          <Skeleton animation="wave" width={"10%"} height={30} sx={{ mb: 2 }} />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Subtitulo color="primary" label={title} />
            <IconButtonCustom onClick={handlerAddCampos}>
              <TooltipInformacao
                positionStart={PositionTooltip.left}
                descricao={tooltipAdd}
              >
                <AddIcon sx={{ fontSize: "1.2rem" }} color="inherit" />
              </TooltipInformacao>
            </IconButtonCustom>
          </Box>
        )}

        <Grid
          container
          rowSpacing={1}
          sx={{ alignItems: "center" }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
       
          {!!dadosForm?.length &&
            dadosForm?.map((dado, index) => (
              <Fragment key={`dadosForm${index}`}>
                <Grid item xs={12} md={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Subtitulo
                      color="primary"
                      label={`${index + 1}º  ${identificaItem}`}
                    />
                    {dadosForm.length > 1 && index > 0 && (
                      <TooltipInformacao
                        positionStart={PositionTooltip.righti}
                        descricao={tooltipDelete}
                      >
                        <span>
                          <IconButtonDelete
                            onClick={() => handlerRemoveCampos(index)}
                          />
                        </span>
                      </TooltipInformacao>
                    )}
                  </Box>
                </Grid>

                {dado?.idBancoNacional > 0 && (
                  <Grid item xs={6} md={6}>
                    {isLoading ? (
                      <Skeleton animation="wave" width={"100%"} height={60} />
                    ) : (
                      <Seletor
                        sx={{ mt: 2 }}
                        setSelect={(e) =>
                          handlerChangeSelect("idBancoNacional", e, index)
                        }
                        list={dado.bancos}
                        size="medium"
                        label="Bancos"
                        value={dado.idBancoNacional}
                        isLoading={isLoading}
                        fullWidth={true}
                      />
                    )}
                  </Grid>
                )}
                {dado?.idTipoContaBancaria > 0 && (
                  <Grid item xs={6} md={6}>
                    {isLoading ? (
                      <Skeleton animation="wave" width={"100%"} height={60} />
                    ) : (
                      <Seletor
                        sx={{ mt: 2, width: "100%" }}
                        setSelect={(e) =>
                          handlerChangeSelect("idTipoContaBancaria", e, index)
                        }
                        list={dado.tiposConta}
                        size="medium"
                        fullWidth
                        label="Tipos de Conta"
                        value={dado.idTipoContaBancaria}
                        isLoading={isLoading}
                      />
                    )}
                  </Grid>
                )}

                {dado?.idTipoSocio > 0 && (
                  <Grid item xs={6} md={3}>
                    <Seletor
                      isLoading={isLoading}
                      setSelect={(e) =>
                        handlerChangeSelect("idTipoSocio", e, index)
                      }
                      list={dado.tiposSocios}
                      size="medium"
                      key={"tipoSocio"}
                      label="Tipo de Sócio"
                      fullWidth={true}
                      value={dado?.idTipoSocio}
                    />
                  </Grid>
                )}
                {dado?.idTipoEstadoCivil > 0 && dado?.idTipoEstadoCivil && (
                  <Grid item xs={6} md={3}>
                    <Seletor
                      isLoading={isLoading}
                      setSelect={(e) =>
                        handlerChangeSelect("idTipoEstadoCivil", e, index)
                      }
                      list={dado.tiposEstadoCivil}
                      size="medium"
                      key={"tipoEstadoCivil"}
                      label="Tipo Estado Civil"
                      fullWidth={true}
                      value={dado?.idTipoEstadoCivil}
                    />
                  </Grid>
                )}

                {dado?.campos &&
                  dado?.campos.map((c, i, array) => (
                    <Fragment key={`campos${i}`}>
                      <CustomGrid index={i} array={array} md={6}>
                        <>
                          {c.tipoMascara === "Celular" &&
                            (isLoading ? (
                              <Skeleton
                                animation="wave"
                                width={"100%"}
                                height={40}
                              />
                            ) : (
                              <>
                                <InputMask
                                  onChange={(e) => handlerChangeValue(e, index)}
                                  mask={"(99)9999-99999"}
                                  value={c.valor}
                                  enable={c.enable}
                                  maskChar="_"
                                >
                                  {({ value, onChange }) => (
                                    <TextField
                                      spellCheck="false"
                                      margin="normal"
                                      required={c.obrigatorio}
                                      fullWidth={true}
                                      label={c.label}
                                      maxLength={c.maxLength}
                                      isLoading={true}
                                      name={c.propriedade}
                                      value={value}
                                      size="medium"
                                      className={classes.campo}
                                      autoFocus
                                      onChange={onChange}
                                    />
                                  )}
                                </InputMask>
                              </>
                            ))}

                          {(c.tipoControle === "Data" ||
                            c.tipoMascara === "Data") &&
                            (isLoading ? (
                              <Skeleton
                                animation="wave"
                                width={"100%"}
                                height={60}
                              />
                            ) : (
                              <CampoData
                                required={c.obrigatorio}
                                enable={c.enable}
                                size="medium"
                                label={c.label}
                                fullWidth={true}
                                name={c.propriedade}
                                value={formatDateValueInput(c.valor)}
                                onChange={(e) => handlerChangeValue(e, index)}
                                className={classes.campo}
                                isLoading={isLoading}
                              />
                            ))}

                          {(c.tipoMascara === "Telefone" ||
                            c.tipoMascara === "TelefoneFixo") &&
                            (isLoading ? (
                              <Skeleton
                                animation="wave"
                                width={"100%"}
                                height={40}
                              />
                            ) : (
                              <>
                                <InputMask
                                  onChange={(e) => handlerChangeValue(e, index)}
                                  mask={"(99)9999-99999"}
                                  value={c.valor}
                                  enable={c.enable}
                                  maskChar="_"
                                >
                                  {({ value, onChange }) => (
                                    <TextField
                                      spellCheck="false"
                                      margin="normal"
                                      required={c.obrigatorio}
                                      fullWidth={true}
                                      label={c.label}
                                      maxLength={c.maxLength}
                                      isLoading={true}
                                      name={c.propriedade}
                                      value={value}
                                      size="medium"
                                      className={classes.campo}
                                      autoFocus
                                      onChange={onChange}
                                    />
                                  )}
                                </InputMask>
                              </>
                            ))}

                          {c.tipoMascara === "CPF" &&
                            (isLoading ? (
                              <Skeleton
                                animation="wave"
                                width={"100%"}
                                height={60}
                              />
                            ) : (
                              <>
                                <InputMask
                                  onChange={(e) => handlerChangeValue(e, index)}
                                  mask={"999.999.999-99"}
                                  value={c.valor}
                                  enable={c.enable}
                                  maskChar="_"
                                >
                                  {({ value, onChange }) => (
                                    <TextField
                                      spellCheck="false"
                                      margin="normal"
                                      required={c.obrigatorio}
                                      fullWidth={true}
                                      label={c.label}
                                      maxLength={c.maxLength}
                                      name={c.propriedade}
                                      value={value}
                                      size="medium"
                                      className={classes.campo}
                                      autoFocus
                                      onChange={onChange}
                                    />
                                  )}
                                </InputMask>
                              </>
                            ))}
                          {c.tipoMascara === "CEP" &&
                            (isLoading ? (
                              <Skeleton
                                animation="wave"
                                width={"100%"}
                                height={60}
                              />
                            ) : (
                              <>
                                <CampoCep
                                  handlerChange={handleChange}
                                  name="cep"
                                  recarregar={cepChanged}
                                  size="medium"
                                  fullWidth={true}
                                  value={enderecoState.cep}
                                  label={"CEP"}
                                  enable={true}
                                  isEnderecoCliente={false}
                                  setEndereco={handleChangeEnderecoByCEP}
                                />
                              </>
                            ))}
                          <Grid sx={{ mt: 1 }}>
                            {c.tipoControle === "Texto" &&
                              c.tipoMascara === null &&
                              (isLoading ? (
                                <Skeleton
                                  animation="wave"
                                  width={"100%"}
                                  height={60}
                                />
                              ) : (
                                <CampoTexto
                                  enable={c.enable}
                                  variant="filled"
                                  size="medium"
                                  fullWidth={true}
                                  value={c.valor}
                                  defaultValue=" "
                                  label={c.label}
                                  name={c.propriedade}
                                  handlerChange={(e) =>
                                    handlerChangeValue(e, index)
                                  }
                                  className={classes.campo}
                                  maximoDigitos={c.maxLength}
                                  required={c.obrigatorio}
                                />
                              ))}
                          </Grid>
                          {c.tipoControle === "Check" &&
                            (isLoading ? (
                              <Skeleton
                                animation="wave"
                                width={"100%"}
                                height={60}
                              />
                            ) : (
                              <FormControl
                                sx={{ mt: 1 }}
                                component="fieldset"
                                variant="standard"
                              >
                                <FormLabel component="legend">
                                  {c.label}
                                </FormLabel>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        value={c.valor}
                                        checked={c.valor}
                                        onChange={(e) =>
                                          handleChangeCheck(e, index)
                                        }
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                        name={`${c.propriedade}`}
                                      />
                                    }
                                    label="Sim"
                                  />
                                </FormGroup>
                              </FormControl>
                            ))}
                          {c.tipoControle === "Numero" && (
                            <CampoNumber
                              isLoading={isLoading}
                              isMonetario={c.tipoMascara === "Monetaria"}
                              enable={c.enable}
                              variant="outlined"
                              size="medium"
                              fullWidth={true}
                              label={c.label}
                              name={c.propriedade}
                              value={c.valor}
                              onChange={(value, propriedade) =>
                                handlerChangeValuePercentual(
                                  value,
                                  propriedade,
                                  index
                                )
                              }
                              className={classes.campo}
                              maximoDigitos={c.maxLength}
                              required={c.obrigatorio}
                            />
                          )}
                        </>
                      </CustomGrid>
                    </Fragment>
                  ))}
              </Fragment>
            ))}
        </Grid>
      </div>
    </>
  );
}
