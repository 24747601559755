import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { saveLocalStorageCarrinhoTemp, saveLocalStorageIdHotsite, clearLocalStorageIdHotsite, getlocalStorageIdHotsite } from '../../util/LocalStorage'
import { useParams } from "react-router-dom";


export default function PreLogin() {
      const [searchParams] = useSearchParams();
      const navigate = useNavigate();
      const { idHotsite } = useParams();


      useEffect(() => {

            let newArrayParams = [];
            let idHotsiteStorage = getlocalStorageIdHotsite();

            if (idHotsite) {
                  saveLocalStorageIdHotsite(idHotsite)
                  if (idHotsiteStorage !== idHotsite)
                        navigate(0)
            }


            if (idHotsiteStorage > 0 && !idHotsite) {
                  clearLocalStorageIdHotsite();
                  navigate(0)
            }

            searchParams.forEach((value, key) => {
                  newArrayParams.push({ [key]: value });
            })

            let objParams = Object.assign({}, ...newArrayParams);
            let natureza = { id: objParams?.Nid ?? 0, descricao: objParams?.Ndescricao ?? "" }
            let formObj = { ...objParams, natureza: { ...natureza } }


            saveLocalStorageCarrinhoTemp(formObj);

            navigate("/login", false)

      }, [])

      return;
}