import { useState } from "react";
import { Grid, useTheme, Typography } from "@mui/material";
import useStyle from "./style";
import InfoIcon from "@mui/icons-material/Info";
import { Subtitulo, Titulo } from "../";
import Pix from "./Pix";
import OperacoesBancarias from "./OperacoesBancarias";
import Hand from "../../assets/images/hand.png";
import { formatMoneyInString } from "../../util/helpers";

const PedidoConcluidoPagamentoEscolhidoSeguroViagem = ({
  pedido,
  isTodosDetalhes = true,
}) => {
  const classes = useStyle();
  const theme = useTheme();
  const [formaPagamento] = useState([{ ...pedido.formaPagamento }]);

  return (
    <>
      <Grid
        md={12}
        sm={10}
        lg={10}
        container
        className={classes.Container}
        justifyContent="center"
        alignItems="center"
      >
        {isTodosDetalhes && (
          <Grid item md={12} sm={12} pl={12}>
            <img width={65} src={Hand} alt="" />
            <Typography variant="h6" sx={{ mb: 1 }}>
              Recebemos seu pedido
            </Typography>
            <Titulo sx={{ fontWeight: "bold", fontSize: 28 }}>
              <>Estamos aguardando o pagamento de:</>
            </Titulo>

            <Titulo color={"primary"} sx={{ fontWeight: "bold", fontSize: 30 }}>
              {formatMoneyInString(
                pedido.valorTotalMoedaNacional !== null &&
                  pedido.valorTotalMoedaNacional !== undefined
                  ? pedido.valorTotalMoedaNacional
                  : pedido.valorTotal
              )}
            </Titulo>

            <br />

            <Subtitulo sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <InfoIcon color="primary" sx={{ mr: theme.spacing(2) }} /> Você
              receberá atualizações do seu pedido por e-mail e pela plataforma
            </Subtitulo>

            <Grid sx={{ mt: 3 }} item md={isTodosDetalhes ? 4 : 12} sm={8}>
              {pedido.formaPagamento.tipoLiquidacao.textId === "PIX" ? (
                <Pix pedido={pedido} />
              ) : (
                <>
                  <OperacoesBancarias
                    formaPagamento={formaPagamento}
                    isTodosDetalhes={isTodosDetalhes}
                    isClienteVendendo={false}
                  />
                </>
              )}
            </Grid>
          </Grid>
        )}

        <Grid sx={{ mt: 3 }} item md={isTodosDetalhes ? 4 : 12} sm={8}>
          {pedido.formaPagamento.tipoLiquidacao.textId === "PIX" ? (
            <Pix pedido={pedido} />
          ) : (
            <>
              <OperacoesBancarias
                formaPagamento={formaPagamento}
                isTodosDetalhes={isTodosDetalhes}
                isClienteVendendo={false}
              />
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PedidoConcluidoPagamentoEscolhidoSeguroViagem;
