import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import NumberFormat from "react-number-format";
import { brlFormatterNotConvert } from "../../../util/CalculosVitrine";
import Skeleton from "@mui/material/Skeleton";

export default function CampoNumber({
  label,
  value,
  size,
  key = null,
  enable = false,
  sx,
  name,
  onChange,
  isLoading,
  currencyFormat = "BRL",
  maximoDigitos = 999,
  variant = "outlined",
  required = false,
  isMonetario = true,
}) {
  return (
    <>
      {isLoading && (
        <Skeleton sx={{}} variant="rectangular" height={40} animation={false} />
      )}
      {!isLoading && (
        <FormControl fullWidth>
          <NumberFormat
            key={key}
            value={value}
            name={name}
            disabled={!enable}
            format={(valor) =>
              isMonetario
                ? brlFormatterNotConvert(valor, currencyFormat, enable)
                : valor
            }
            displayType="text"
            sx={sx}
            //inputProps={{ maxLength: maximoDigitos > 0 ? maximoDigitos : 15 }}
            maxLength={{ maxLength: maximoDigitos > 0 ? maximoDigitos : 20 }}
            renderText={(valor, props) => (
              <>
                <TextField
                  spellCheck="false"
                  {...props}
                  size={size}
                  type="text"
                  variant={variant}
                  required={required}
                  value={valor}
                  label={label}
                  onChange={(e) => {
                    let campoValue = "";

                    if (!Number(e.target.value)) {
                      campoValue = e.target.value
                        .replace("R$", "")
                        .replaceAll(".", "")
                        .replace(",", "");
                    } else {
                      campoValue = e.target.value;
                    }

                    if (isMonetario)
                      campoValue = parseFloat(campoValue / 100).toFixed(2);

                    onChange(campoValue, name);
                  }}
                />
              </>
            )}
          ></NumberFormat>
        </FormControl>
      )}
    </>
  );
}
